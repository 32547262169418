@use "../abstract" as *;

.pro {
  &__container {
    background-color: var(--white);
    padding: 0 31px 0 40px;
    margin-bottom: 30px;
    border-radius: 8px;

    s {
      color: var(--new-grey);
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;

    border-bottom: 1px solid var(--grey2);

    &:last-child {
      border-bottom: none;
    }
  }

  &__sell {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--grey2);
    padding: 20px 0;

    &:last-child {
      border-right: none;
    }

    &-head {
      padding-top: 36px;
      padding-bottom: 25px;
    }
    &-footer {
      padding-top: 25px;
      padding-bottom: 43px;
    }
    &-title {
      justify-content: start;
      align-items: flex-start;
    }
  }

  &__btn {
    padding: 21px 0;
    width: 80%;
    border-radius: 5px;
    font-family: var(--font);
    border: 2px solid transparent;
    font-size: 16px;
    transition: var(--speed);
    font-weight: 700;
    display: block;
    background-color: var(--new-blue);
    margin-top: 32px;
    color: var(--white);

    &:not(:disabled) {
      &:hover {
        cursor: pointer;
        background-color: transparent;
        color: var(--new-blue);
        border-color: var(--new-blue);
      }
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #f3f4f8;
      color: var(--dark);
    }
  }
}
