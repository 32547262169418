.main-chart {
  .item {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    height: 445px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 #F1F2FA;

    @media (max-width: 1200px) {
      height: 350px;
    }
    @media (max-width: 992px) {
      height: 400px;
    }
    @media (max-width: 768px) {
      height: 400px;
    }
  }
}

.metrics {
  .item {
    @media (min-width: 992px) {
      width: auto;
    }
    @media (max-width: 992px) {
      width: calc(100% / 2);
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}
