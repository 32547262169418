@use "../abstract" as *;

.ls {
  &-auth {
    &__main {
      min-height: 100vh;
    }
    &__leftContainer {
      display: flex;
      flex-direction: column !important;
      align-items: center;
      justify-content: space-between;
      background-color: var(--white);
      width: 50vw;
    }
    &__rightContainer {
      background-color: #3f3f3f;
      background-image: url(../../assets/imgs/login_background.png);
      width: 30vw;
      background-position: center center;
      background-size: cover;

      @media (max-width: 576px) {
        height: 300px;
        background-position: 50% 15%;
        background-size: auto;
      }
    }
  }
}
