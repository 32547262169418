@use "../abstract" as *;

.ls {
  &-modal {
    &__container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 10px 24px 0px #5c589133;
      min-width: 480px;
      overflow-y: auto;
      max-height: 100%;
      padding: 30px;

      &.max-width-limit {
        max-width: 480px;
      }

      &.w-auto {
        width: auto;
      }

      @media (max-width: 575px) {
        min-width: calc(100% - 10px);
        padding: 20px 10px;
      }
    }
    &__closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;

      @media (max-width: 575px) {
        top: 10px;
        right: 10px;
      }
    }
  }
}
