.courseQuality {
  .days-block {
    padding-left: 30px;
    flex-direction: column;
    width: calc(100% / 7);
    margin-top: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .percentage {
    height: 100%;
    border-radius: 16px;
    background-color: #c3efdd;
    border: 1px solid var(--grey-2);
    width: 40%;

    &-container {
      height: 9px;
      width: 100%;
      border-radius: 16px;
      background-color: #f4f4f4;
      border: 1px solid var(--grey-2);
    }
  }
  .scroll-horizontal {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 20px;
  }
  .container {
    padding: 50px 25px 30px 25px;
    border: 1px solid var(--grey-2);
    border-radius: 20px;
    width: 100%;
    overflow: hidden;
    display: flex;
  }
}
