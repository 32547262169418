@import "components/statuses";
@import "components/table";
@import "components/auth";
@import "components/pro";
@import "components/inputs";
@import "components/premium";
@import "components/dayPicker";
@import "components/settings";
@import "components/page";
@import "components/modal";
@import "components/navbar";
@import "components/learnabilty";
@import "components/course-quality";
@import "components/tabs";
@import "components/dashboard";
@import "components/portal";
@import "components/buttons";
@import "components/support";

:root {
  --primary: #2b4eff;
  --white: rgba(255, 255, 255, 1);
  --speed: 0.25s ease-out;
  --blue: #2b4eff;
  --orange: #ffb200;
  --red: #ff3a29;
  --new-blue: #2b4eff;

  --grey2: #d5d5db;
  --new-dark: #0e1133;

  --dark: #212339;
  --green: #34b53a;
  --new-grey: #9ba0ad;

  --new-grey-1: #f3f4f8;
  --grey-2: #d8dbe1;
  --primary-buttons-dark: #414562;
  --background: #f5f5f5;

  --light-red: #ffdbd3;
  --light-blue: #ccf8fe;
  --light-green: #e2fbd7;

  --primary-content: var(--new-grey);
  --font: "Manrope", serif;

  --toastify-toast-width: 100%;
  --toastify-color-success: #1BD29B;
  --toastify-icon-color-success: #1BD29B;
  --toastify-color-error: #FF674F;
  --toastify-icon-color-error: #FF674F;
  --toastify-color-warning: #F00;
  --toastify-icon-color-warning: #FFF;
  --toastify-color-info: #516EFF;
  --toastify-icon-color-info: #516EFF;
  --toastify-text-color-light: #000;

}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 200;
  src: url(../assets/fonts/Manrope-ExtraLight.ttf) format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  src: url(../assets/fonts/Manrope-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Manrope-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/Manrope-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/Manrope-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/Manrope-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/Manrope-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  src: url(../assets/fonts/Manrope-ExtraBold.ttf) format("truetype");
}

@counter-style olemoji {
  system: numeric;
  symbols: "0️⃣ " "1️⃣ " "2️⃣ " "3️⃣ " "4️⃣ " "5️⃣ " "6️⃣ " "7️⃣ " "8️⃣ " "9️⃣ ";
  suffix: "  ";
}

@counter-style ulemoji {
  system: cyclic;
  symbols: "🔸";
  suffix: "  ";
}

@counter-style circled-alpha {
  system: fixed;
  symbols: Ⓐ Ⓑ Ⓒ Ⓓ Ⓔ Ⓕ Ⓖ Ⓗ Ⓘ Ⓙ Ⓚ Ⓛ Ⓜ Ⓝ Ⓞ Ⓟ Ⓠ Ⓡ Ⓢ Ⓣ Ⓤ Ⓥ Ⓦ Ⓧ Ⓨ Ⓩ;
  suffix: " ";
}

html,
body {
  background-color: var(--white);
  min-height: 100vh;
}

.brsr {
  &-no {
    border-radius: 0px !important;
  }
  &-1 {
    border-radius: 4px !important;
  }
  &-2 {
    border-radius: 8px !important;
  }
  &-3 {
    border-radius: 12px !important;
  }
  &-4 {
    border-radius: 16px !important;
  }
  &-5 {
    border-radius: 20px !important;
  }
  &-extra {
    border-radius: 200px !important;
  }
}

.btn-unstyled {
  display: inline-block;
  border: none;
  padding: 0;
  outline: none;
  font-family: var(--font);
  background-color: transparent;

  svg {
    width: 19px;
    height: 19px;
  }

  &:hover {
    cursor: pointer;
  }
}

.w-100 {
  width: 100% !important;
}

.alert {
  &-error {
    background-color: var(--light-red);
  }
  &-info {
    background-color: var(--light-blue);
  }
  &-success {
    background-color: var(--light-green);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#root,
.App {
  min-height: 100vh;
}

.img {
  max-width: 100%;
}

.minh-100 {
  min-height: 100vh;
}

.bold {
  font-family: var(--font);
  font-weight: 700 !important;
  font-size: 16px !important;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

a {
  text-decoration: none;
  font-family: var(--font);
  &.text-button {
    transition: var(--speed);
    text-decoration: underline;
    &.grey {
      color: var(--new-grey);
    }

    &:hover {
      color: var(--dark);

      text-decoration: underline;
    }
  }
  &:hover {
    text-decoration: none;
  }
}

.mb {
  &-5 {
    margin-bottom: 5px;
  }
  &-10 {
    margin-bottom: 10px;
  }
  &-15 {
    margin-bottom: 15px;
  }
  &-20 {
    margin-bottom: 20px;
  }
  &-25 {
    margin-bottom: 25px;
  }
  &-30 {
    margin-bottom: 30px;
  }
  &-40 {
    margin-bottom: 40px;
  }
}

.ml {
  &-5 {
    margin-left: 5px;
  }
  &-10 {
    margin-left: 10px;
  }
  &-15 {
    margin-left: 15px;
  }
  &-20 {
    margin-left: 20px;
  }
  &-25 {
    margin-left: 25px;
  }
  &-30 {
    margin-left: 30px;
  }
  &-40 {
    margin-left: 40px;
  }
}

.line {
  &-normal {
    line-height: normal;
  }
  &-0 {
    line-height: 0;
  }
}

.mr {
  &-5 {
    margin-right: 5px;
  }
  &-10 {
    margin-right: 10px;
  }
  &-15 {
    margin-right: 15px;
  }
  &-20 {
    margin-right: 20px;
  }
  &-25 {
    margin-right: 25px;
  }
  &-30 {
    margin-right: 30px;
  }
  &-40 {
    margin-right: 40px;
  }
}

.d {
  &-flex {
    display: flex;
  }
}

.align {
  &-self {
    &-center {
      align-self: center;
    }
  }
}

label {
  margin-bottom: 0px;

  &:hover {
    cursor: pointer;
  }
}

.hidden {
  width: 0;
  height: 0;
  // position: absolute;
  // visibility: hidden;
  opacity: 0;
}

button {
  &:focus {
    outline: none;
  }
}

.h-100 {
  height: 100%;
}

.flex {
  &-1 {
    flex: 1;
  }
}

.horizontal-scroll {
  max-width: 100%;
  overflow-x: auto;
}

.ls {
  &-tooltip {
    .MuiTooltip-tooltip {
      background-color: var(--new-blue);
      color: var(--white);
      padding: 7px 9px 7px 12px;
      font-family: var(--font);
      font-size: 13px;
    }
    .MuiTooltip-arrow {
      color: var(--new-blue) !important;
    }

    a {
      color: var(--white);
      text-decoration: underline;
      transition: var(--speed);
      &:hover {
        color: var(--grey-2);
      }
    }
  }
}

.color {
  &-grey {
    color: var(--new-grey) !important;
  }
}

.dashed {
  border-width: 0px;
  border-style: dashed;
  border-color: var(--new-grey);

  &-bottom {
    border-bottom-width: 1px;
  }
}

.p {
  &-fixed {
    position: fixed;
  }
}

.arrow-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  opacity: 0;
  transform: translateY(100px);
  visibility: hidden;
  align-items: center;
  transition: var(--speed);
  justify-content: center;
  overflow: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }

  svg {
    transform: rotate(180deg);
  }

  &:hover {
    cursor: pointer;
  }
}

body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #aaaaaf;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

body *::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

/* background of the scrollbar except button or resizer */
body *::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
body *::-webkit-scrollbar-thumb {
  background-color: #cbcaca;
  border-radius: 16px;
  border: 3px solid transparent;
}

/* set button(top and bottom of the scrollbar) */
body *::-webkit-scrollbar-button {
  display: none;
}

.hide {
  &-scale {
    transform: scale(0) !important;
  }
}

.mt {
  &-0 {
    margin-top: 0 !important;
  }
}

.mb {
  &-0 {
    margin-bottom: 0 !important;
  }
}

.highlight {
  animation: 0.8s highlight ease infinite alternate;
}

.Mui {
  &Checkbox {
    &-root {
      &.Mui-error {
        svg {
          & > * {
            &:nth-child(1) {
              fill: #ffe1e1;
            }
            &:nth-child(2) {
              stroke: var(--red);
            }
          }
        }
      }
    }
  }
}

.Toastify__toast-container{
  padding: 0;
}

.Toastify__toast--success{
  background-color: #EBF7EE;
}

.Toastify__toast--error{
  background-color: #FEF7EA;
}

.Toastify__toast--warning{
  background-color: #F00;
  color: #FFF;
}

.Toastify__toast--warning .Toastify__close-button{
  color: #212339;
  opacity: 1;
}

.Toastify__toast--info{
  background-color: #E5EFFA;
}

.Toastify__close-button{
  align-self: center;
}

.Toastify__toast-container--top-right{
  top: 0;
  right: 0;
}

@keyframes highlight {
  0% {
    background-color: #ff8a8a;
  }
  100% {
    background-color: var(--white);
  }
}

.p {
  &-relative {
    position: relative;
  }
}
