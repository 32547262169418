@use "../abstract" as *;

.ls {
  &-navbar {
    background-color: var(--white);
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 34px;
    box-shadow: 0px 1px 0px 0px #0000000d;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;

    @media (max-width: 767px) {
      padding: 0;
    }
    .logo {
      display: flex;
      @media (max-width: 767px) {
        order: 2;
        margin: 0 auto;
      }
    }
    .profileBtn {
      order: 1;
      position: absolute;
      right: var(--mobile-px);

      .pro-icon {
        position: absolute;
        top: -5px;
        right: -7px;
      }
    }
    .menu-toggler {
      position: absolute;
      right: 8px;
    }

    &.course-details {
      padding-left: 13px;
      padding-right: 5px;
    }
  }
  &-navbarClone {
    height: 60px;
  }
}

.dropdownMenu {
  & .MuiPaper-root {
    box-shadow: 0px 10px 24px 0px #5c589133;
    overflow: visible;
    margin-top: 6.5px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 14;
      background-color: var(--white);
      transform: translateY(-50%) rotate(45deg);
    }
  }
  & .MuiList-root {
    padding: 10px;
    @media (max-width: 767px) {
      padding: 5px;
    }
  }
  & .MuiMenuItem-root {
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 20px;
    @media (max-width: 767px) {
      padding-left: 5px;
      padding-right: 10px;
      min-height: auto;
    }
  }
  & .MuiMenuItem-root:hover {
    background-color: #d9ebff;
  }
}
