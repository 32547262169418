@use "../abstract" as *;

.ls {
  &-page {
    &__top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
}
