.settings {
  &__profile {
    &-wrapper {
      border-radius: 8px;
      background-color: var(--white);
      box-shadow: 0px 5px 10px #f1f2fa;
      padding: 41px 43px;

      @media (max-width: 1200px) {
        padding: 20px 20px;
      }
    }
  }
}
