@use "../abstract" as *;

.ls {
  &-tabs {
    &__block {
      overflow: hidden;

      @media (max-width: 475px) {
        .ls_tabs__list {
          min-width: 1000px;
        }
      }
    }
  }

  &-tabs {
    &__list {
      margin-left: -10px; // -10px if "With Magic" tab is hidden, -40px if AI tab shown
      height: 34px;
      width: calc(100% + 20px * 2);
      display: flex;
      flex-wrap: nowrap;

      @media (max-width: 475px) {
        // min-width: 1000px;
      }
    }
    &__horizontal {
      overflow: hidden;
      overflow-x: auto;
      margin-top: 30px;
      scrollbar-width: thin;
      scrollbar-color: transparent;
      white-space: nowrap;
      scrollbar-gutter: stable;
      -webkit-overflow-scrolling: touch;
      margin-bottom: 30px;
      padding-bottom: 11px;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        opacity: 0;
        display: none;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &.magicTabs{
        margin-bottom: 0;
        padding-bottom: 10px;
      }
    }
  }
  &-tab {
    background-color: transparent;
    font-size: 18px;
    color: var(--primary);
    font-family: var(--font);
    font-weight: 700;
    min-width: max-content;
    padding: 0;
    border: none;
    outline: none;
    text-align: center;
    margin-left: 40px;
    position: relative;

    &::after {
      content: "";
      width: 0;
      height: 3px;
      position: absolute;
      bottom: -11px;
      left: 0;
      right: 0;
      margin: 0 auto;
      transition: var(--speed);
      background-color: var(--primary);
      opacity: 0;
      visibility: hidden;
    }

    &.magicTabs{
      background-color: var(--grey-2);
      padding: 10px 15px;
      border-radius: 8px 8px 0 0;
      height: 45px;
      font-size: 16px;
      color: var(--new-grey);

      &.Mui-selected{
        background-color: var(--white);
      }

      &.magic{
        background: linear-gradient(90deg, #AE2DFD 1.56%, #FF54B7 96.88%);
        color: var(--white);
        background-size: 600%;
        animation: anime 4s linear infinite;

        @keyframes anime {
          0% {
            background-position: 0 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0 50%
          }
        }

        &.Mui-selected {
          background: var(--white);
          & .magic-text1{
            color: #AE2DFC;
          }& .magic-text2{
            color: #BA33F2;
          }& .magic-text3{
            color: #C036EC;
          }& .magic-text4{
            color: #C639E7;
          }& .magic-text5{
            color: #CC3CE1;
          }& .magic-text6{
            color: #D43FDB;
          }& .magic-text7{
            color: #D942D6;
          }& .magic-text8{
            color: #E246CF;
          }& .magic-text9{
            color: #EA4AC8;
          }& .magic-text10{
            color: #F04CC3;
          }& .magic-text11{
            color: #F44FBF;
          }& .magic-text12{
            color: #FA51BA;
          }& .magic-text13{
            color: #FF53B6;
          }
        }
      }

      &::after{
        display: none;
      }
      &:last-child{
        margin-left: 10px;
      }
    }

    &.Mui-selected {
      color: var(--black);

      &::after {
        width: 100%;
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &__panel {
    }

    &.support-tab{
      font-size: 16px;
      font-weight: 400;
      color: var(--toastify-color-info);
      margin: 20px 0 0 0;
      border-bottom: 1px dashed var(--toastify-color-info);
      min-width: 100%;
      text-align: left;

      &.Mui-selected{
        color: var(--new-dark);
        border: none;
      }

      &:first-child{
        margin: 0;
      }

      &::after{
        display: none;
      }

      &-panel{
        min-width: 400px;
        font-size: 14px;
        font-weight: 400;
        margin: 20px 0 0 0;
        color: var(--new-dark);
        text-align: left;
        padding: 0 28px;
        cursor: inherit;
      }
    }
  }
}

.articles-modal-container{
  height: 87%;
  width: 50%;
}


.support-tabs-container{
  display: inline-grid;
  padding-right: 16px;
}

.article-content{
  border-radius: 6px;
  border: 1px solid #D5D5DB;
  background: #F8F8F8;
  height: 68vh;
  overflow: scroll;
}
