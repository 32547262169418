.subscriptions {
  &__status {
    color: #414562;
    font-size: 14px;
    font-family: var(--font);
    font-weight: 400;
    padding: 0 6px;
    text-transform: capitalize;
    border-radius: 4px;

    &-paused {
      background-color: #ffd6cc;
    }
    &-pending {
      background-color: rgba(43, 78, 255, 0.3);
    }
    &-active {
      background-color: rgba(255, 180, 31, 0.3);
    }
    &-completed {
      background-color: rgba(27, 210, 155, 0.4);
    }
    &-cancelled {
      background-color: rgba(255, 58, 41, 0.3);
    }
    &-unpaid {
      background-color: #ffebcc;
    }
    &-none {
      background-color: #ffdbd3;
    }
    &-inactive {
      background-color: #f8f8f8;
    }
  }
}
