@use "../abstract" as *;

.ls {
  &-table {
    color: #212529;
    width: 100%;
    border-color: #dee2e6;
    vertical-align: top;

    &.fixed {
      table-layout: fixed;
    }

    &.with-pagination {
      & ~ .ls-table__searchInput {
        top: 5px;
      }
    }

    &__block {
      background-color: var(--white);
      width: 100%;
      flex: 1;
      border-radius: 20px;
      padding: 20px 0;
      position: relative;
    }

    &__cell {
      color: var(--new-dark);
      padding: 20px 0;
      border-color: transparent;
      padding-right: 10px;
      font-family: var(--font);
      overflow: hidden;
      font-size: 15px;
      font-weight: 400;

      &:last-child {
        padding-right: 0;
      }
      h4 {
        flex: 1;
      }
    }

    &__pictureSmall {
      width: 120px;
      object-fit: cover;
      height: 88px;
      border-radius: 8px;
    }

    &__absolutePrice {
      background-color: var(--white);
      position: absolute;
      bottom: 5px;
      left: 5px;
      right: 5px;
      padding: 5px 0;
      text-align: center;
      border-radius: 4px;
      font-weight: 700;
      font-size: 10px;
      text-transform: uppercase;
      color: var(--primary);
      font-family: var(--font);
    }

    &__actions {
      padding: 0px 30px;

      @media (max-width: 767px) {
        padding: 0 10px;
      }
    }

    &__createButtons {
      min-height: 42px;
      &.btn {
        border-radius: 28px;
        padding: 0px 15px;
        height: 40px;
        margin-left: 16px;
        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__container {
      padding: 0px 30px;
      position: relative;
      border-radius: 20px;

      @media (max-width: 996px) {
        padding: 0px;
      }
    }

    &__row {
      box-shadow: inset 0px -1px 0px rgba(32, 14, 50, 0.1);

      &.isDragging {
        background-color: var(--white);
        opacity: 0.5;
        display: flex;
        box-shadow: 5px 6px 3px 0px rgba(32, 14, 50, 0.06);
        align-items: center;
        justify-content: space-between;

        & td {
          flex: 0 1 130px;
          display: flex;
          align-items: center;
          height: 100%;

          &:nth-child(2),
          &:last-child {
            flex: 1;
            justify-content: flex-start;
          }
          &:nth-child(1) {
            flex: 0 1 40px;
          }

          &:last-child {
            justify-content: flex-end;
          }
        }
      }

      &:last-child {
        box-shadow: none;
      }
    }

    &__horizontal {
      overflow-x: auto;

      @media (max-width: 996px) {
        padding: 0 30px;
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;
      }
      table {
        @media (max-width: 996px) {
          flex: 1 1 100%;
          min-width: calc(100vh * 2);
        }
      }
    }

    &__searchInput {
      background-color: #f8f8f8;
      position: absolute;
      top: 0px;
      right: 30px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      overflow: hidden;
      padding-right: 10px;

      &.p-relative {
        position: relative;
        right: unset;
        margin-left: auto;
        max-width: min-content;
      }

      @media (max-width: 996px) {
        position: relative;
        margin-left: 30px;
        margin-bottom: 10px;
        display: inline-flex;
      }

      label {
        display: inline;
        line-height: 0;
        margin-bottom: 0 !important;
      }

      input {
        background-color: transparent;
        font-size: 14px;
        border: none;
        color: var(--new-dark);
        font-family: var(--font);
        padding: 9px 10px;

        &:focus {
          outline: none;
        }
      }
    }

    &__head {
      &-row {
        box-shadow: inset 0px -1px 0px rgba(32, 14, 50, 0.1);
      }
      &-cell {
        padding: 15px 0;
        color: var(--new-grey);
        font-size: 15px;
        vertical-align: middle;
        align-items: center;
        font-weight: 500;
        font-family: var(--font);

        & > span {
          margin-left: 5px;
          line-height: normal;
          font-size: 0px;
          height: auto;
        }
        & .ls-table__head-icons {
          visibility: hidden;
          opacity: 0;
        }
        &:hover .ls-table__head-icons {
          visibility: visible;
          transition: var(--speed);
          opacity: 1;
        }
      }
    }

    &__footer {
      margin-top: 20px;
      display: flex;

      &-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        width: 42px;
        outline: none;
        border: none;
        height: 42px;
        border-radius: 50%;

        &:disabled {
          opacity: 0.5;
          &:hover {
            cursor: not-allowed;
          }
        }
      }

      &-textfield {
        min-width: 42px;
        border-radius: 4px;
        border: 1px solid var(--grey-2);
        max-width: 60px;
        height: 42px;
        padding: 0;
        display: inline-block;
        text-align: center;
      }
    }
  }
}

.ai-created-marker {
  background: linear-gradient(90deg, #AE2DFD 1.56%, #FF54B7 96.88%);
  border-radius: 10px 0;
  padding: 3px 7px;
  color: var(--white);
  font-size: 13px;
  font-weight: 700;
}
