.portal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .confirm-dialog {
      z-index: 1000000000000111;
      padding: 30px;
      background-color: white;
      position: absolute;
      top: 50%;
      width: 480px;
      max-width: 480px;
      max-height: 100%;
      overflow-y: auto;
      box-shadow: 0px 10px 24px 0px #5c589133;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;

      @media (max-width: 500px) {
        max-width: 100%;
      }

      h4 {
        color: var(--black);
        font-size: 22px;
        font-family: var(--font);
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        color: #767676;
        font-size: 16px;
        font-family: var(--font);
        font-weight: 500;
      }

      &__footer {
        display: flex;
        margin-top: 25px;
        justify-content: space-between;
      }

      .btn {
        display: inline-block;
        padding: 10px 20px;
        transition: var(--speed);
        background-color: #eef1f6;
        border-radius: 8px;
        border: 2px solid transparent;

        &:hover {
          cursor: pointer;
        }

        &-icon {
          background-color: transparent;
          width: 30px;
          height: 30px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          border: none;
          border-radius: 50%;
          position: absolute;
          right: 15px;
          top: 15px;
          &:active {
            background-color: var(--grey2);
          }
          &:hover {
            cursor: pointer;
          }
        }

        &-cancel {
          &:hover {
            background-color: var(--red);
            color: var(--white);
          }
        }

        &-confirm {
          border-color: var(--primary);
          background-color: var(--primary);
          color: var(--white);

          &:hover {
            background-color: transparent;
            color: var(--primary);
          }
        }
      }
    }
  }
}
