.support-item {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 #F1F2FA;
  padding: 27px 28px 36px;
  min-height: 306px;
  align-items: center;

  &.MuiGrid-item{
    @media screen and (min-width: 900px) {
      padding-left: 28px !important;
    }
    @media screen and (min-width: 600px) {
      padding-left: 28px !important;
    }
    @media screen and (min-width: 0) {
      padding-left: 28px !important;
    }
  }
}

.updates-span{
  font-size: 13px;
  background-color: #FFF5DA;
  padding: 6px 10px;
}

.support-link{
  text-decoration: underline;
  color: var(--new-blue);

  &:hover{
    color: var(--new-blue);
  }

  &.dotted{
    border-bottom: 1px dashed var(--new-blue);
    text-decoration: none;
  }
}

.send-letter-button{
  position: absolute;
  top: 260px;

  @media screen and (max-width: 1075px) {
      top: 285px
  }
}

.support-instructions-articles{
  background: var(--white);
  box-shadow: 0 5px 10px #F1F2FA;
  border-radius: 8px;
  padding: 40px 40px 48px;
  flex-direction: column;
  color: var(--new-dark);

  .instructions-articles-header{
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  ul{
    list-style: none;
    font-family: Manrope, serif;
    padding-inline-start: 0;

    li{
      margin: 5px 0;

      svg{
        vertical-align: bottom;
        margin-left: 8px;
      }
    }
  }
}

.support-search{
  background-color: var(--new-grey-1);
  display: flex;
  align-items: center;
  border-radius: 30px;
  overflow: hidden;
  padding-left: 10px;

  & label {
    display: inline;
    line-height: 0;
    margin-bottom: 0 !important;
    margin-right: 3px;
  }

  & input{
    border: none;
    background: transparent;

    &:focus {
      outline: none;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 16px;
  }
}
