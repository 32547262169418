.btn {
  &-toggle {
    background-color: transparent;
    transition: var(--speed);
    display: inline-flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-family: var(--font);
    border: 2px solid var(--new-grey);
    color: var(--new-grey);
    padding: 6px;
    border-radius: 4px;

    // &.focus {
    // }

    &:hover {
      cursor: pointer;
      color: var(--black);
    }

    &.active {
      border-color: var(--primary);
      border-width: 2px;
      color: var(--black);
    }
  }
}

.btn_add_lesson{
  justify-content: flex-start !important;
  box-shadow: 0 0 5px 0 #9BA0AD;
  color: #9BA0AD !important;
  text-transform: uppercase !important;

  &:hover{
    background-color: var(--white) !important;
    color: var(--toastify-color-info) !important;
    box-shadow: 0 0 10px 0 #9BA0AD;

    & svg{
      fill: var(--toastify-color-info) !important;
    }
  }
}
